import { connect, ConnectedProps } from "react-redux";

import { AvailabilitySearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { areRequestParametersChanged } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    areRequestParametersChanged: areRequestParametersChanged(state),
  };
};

const mapDispatchToProps = () => {
  return {
    resetFilters: () => {},
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilitySearchControl = connector(
  AvailabilitySearchControl
);
