import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { RouterProps } from "react-router";
import { ExperiencesAvailabilityConnectorProps } from "./container";
import clsx from "clsx";
import {
  B2BLoadingPopup,
  ExperiencesSearchLoadingImage,
  formatShortDate,
  useDeviceTypes,
} from "halifax";
import { config } from "../../api/config";
import "./styles.scss";
import * as textConstants from "../../utils/textConstants";
import { RewardsAccountSelection } from "../rewards/components";

import { Header } from "halifax";
import {
  FETCH_AVAILABILITY_TEXT,
  VIEWING_EXPERIENCES_HEADER_TEXT,
} from "./textConstants";
import { ClientContext } from "../../App";
import { PATH_HOME } from "../../utils/paths";
import { AvailabilitySearchControl } from "./components/AvailabilitySearchControl";
import { TravelWalletDrawer } from "../travel-wallet/components";
import { ExperiencesAvailabilityCallState } from "./reducer";
import { getEarnMessageToDisplay } from "../search/textConstants";
import { KeywordSearch } from "./components/KeywordSearch";
import { AvailabilitySplitView } from "./components/AvailabilitySplitView";
import { ExperiencesCategoryRow } from "./components/ExperiencesCategoryRow";

export interface IExperiencesAvailability
  extends ExperiencesAvailabilityConnectorProps,
    RouterProps {}

export const ExperiencesAvailability = (props: IExperiencesAvailability) => {
  const {
    history,
    fromDate,
    untilDate,
    location,
    experiencesAvailabilityCallState,
    largestValueAccount,
    fetchRewardsAccountsCallState,
    fetchInitialExperiencesAvailability,
    experiencesMap,
    experiencesByCategory,
    rewardsKey,
  } = props;

  const { logo } = useContext(ClientContext);

  useEffect(() => {
    if (
      experiencesAvailabilityCallState ===
      ExperiencesAvailabilityCallState.NotCalled
    ) {
      fetchInitialExperiencesAvailability(history);
    }
  }, [experiencesAvailabilityCallState]);

  const headerDateText =
    fromDate && untilDate ? (
      <>
        <Typography variant={"body2"}>
          {textConstants.DATES_TEXT(
            formatShortDate(fromDate),
            formatShortDate(untilDate)
          )}
        </Typography>
      </>
    ) : null;

  const { matchesMobile } = useDeviceTypes();

  const renderRightSection = () => {
    return (
      <>
        <KeywordSearch />
        <Box className={clsx("experiences-category-row-container")}>
          {experiencesByCategory.map((category) => {
            return (
              <ExperiencesCategoryRow
                history={history}
                experiencesMap={experiencesMap}
                experienceByCategory={category}
                rewardsKey={rewardsKey}
                largestValueAccount={largestValueAccount}
              />
            );
          })}
        </Box>
      </>
    );
  };
  const renderDesktopView = () => {
    return (
      <Box
        className={clsx("experiences-availability-container", config.TENANT)}
      >
        <Header
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>
              <Box className={"rewards-account-section-travel-details"}>
                <Typography variant={"body1"} tabIndex={0}>
                  {VIEWING_EXPERIENCES_HEADER_TEXT(
                    location ? location.label : ""
                  )}
                </Typography>
                {headerDateText}
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-experiences-availability-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        />
        <AvailabilitySearchControl />
        <AvailabilitySplitView
          left={<></>}
          leftClassName={"left-section"}
          right={renderRightSection()}
          rightClassName={"right-section"}
          className={"experiences-availability-split-view"}
        />
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx("experiences-availability-root", {
          "full-width": !matchesMobile,
        })}
      >
        {renderDesktopView()}
        <>
          {experiencesAvailabilityCallState ===
          ExperiencesAvailabilityCallState.InitialSearchCallInProcess ? (
            <B2BLoadingPopup
              open={
                experiencesAvailabilityCallState ===
                ExperiencesAvailabilityCallState.InitialSearchCallInProcess
              }
              message={FETCH_AVAILABILITY_TEXT}
              secondaryMessage={getEarnMessageToDisplay(
                fetchRewardsAccountsCallState,
                largestValueAccount
              )}
              image={ExperiencesSearchLoadingImage}
              className={clsx("experiences-availability-loading-popup")}
              popupSize={matchesMobile ? "mobile" : "desktop"}
            />
          ) : null}
        </>
      </Box>
    </>
  );
};
