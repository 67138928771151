import {
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilityResponse,
  ICategorizedResponse,
  IResult,
} from "redmond";
import { actions, actionTypes } from "../actions";

export enum ExperiencesAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty
  Complete = "Complete",
  Failed = "Failed",
}

export interface IExperiencesAvailabilityState {
  availabilityResponse?: ExperiencesAvailabilityResponse;
  experiencesAvailabilityCallState: ExperiencesAvailabilityCallState;
  keyword: IResult | null;
  keywordCategories: ICategorizedResponse[];
  keywordCategoriesLoading: boolean | null;
  hasKeywordAutocompleteError: boolean;
}

export const initialState: IExperiencesAvailabilityState = {
  availabilityResponse: undefined,
  experiencesAvailabilityCallState: ExperiencesAvailabilityCallState.NotCalled,
  keyword: null,
  keywordCategories: [],
  keywordCategoriesLoading: false,
  hasKeywordAutocompleteError: false,
};

export const reducer = (
  state: IExperiencesAvailabilityState = initialState,
  action: actions.ExperiencesAvailabilityActions
): IExperiencesAvailabilityState => {
  switch (action.type) {
    case actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY:
      return {
        ...state,
        availabilityResponse: undefined,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.InitialSearchCallInProcess,
      };
    case actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY:
      return {
        ...state,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.FollowUpSearchCallInProcess,
      };
    case actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS:
      const isInitialRequest =
        action.responseType ===
        ExperiencesAvailabilityRequestEnum.InitialSearch;
      const hasCompletedRequest =
        !action.payload.nextPageToken ||
        action.payload.experiences.length === 0;
      if (isInitialRequest) {
        return {
          ...state,
          availabilityResponse: action.payload,
          experiencesAvailabilityCallState: hasCompletedRequest
            ? ExperiencesAvailabilityCallState.Complete
            : ExperiencesAvailabilityCallState.InitialSearchCallSuccess,
        };
      }

      return {
        ...state,
        availabilityResponse: {
          ...action.payload,
          // each FollowUpSearch fetches more experiences based on the specified page size;
          // the new experiences result continues from where the previous call ends.
          experiences: [
            ...(state.availabilityResponse?.experiences || []),
            ...action.payload.experiences,
          ],
        },
        experiencesAvailabilityCallState: hasCompletedRequest
          ? ExperiencesAvailabilityCallState.Complete
          : ExperiencesAvailabilityCallState.FollowUpSearchCallSuccess,
      };

    case actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED:
      return {
        ...state,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.Failed,
      };

    case actionTypes.SET_KEYWORD:
      const { keyword } = action;
      return {
        ...state,
        keyword: keyword ? { ...keyword } : null,
        keywordCategoriesLoading: false,
        hasKeywordAutocompleteError: false,
      };

    case actionTypes.SET_KEYWORD_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        keywordCategories: categories,
        keywordCategoriesLoading: false,
      };

    case actionTypes.FETCH_KEYWORD_CATEGORIES:
      return {
        ...state,
        keywordCategories: [],
        keywordCategoriesLoading: true,
      };

    case actionTypes.SET_KEYWORD_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasKeywordAutocompleteError: action.error,
        keywordCategoriesLoading: false,
      };
    }

    default:
      return { ...state };
  }
};

export * from "./selectors";
