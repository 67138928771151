import React, { useState } from "react";
import { DesktopPopupModal } from "halifax";
import { Button } from "@material-ui/core";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { TripCategory } from "redmond";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../MonthAndDatePicker";
import { DESKTOP_CALENDAR_TITLE_RANGE } from "./textConstants";
import { ConnectedDesktopCalendarPicker } from "./container";
import { useDispatch } from "react-redux";
import { ISetFromDate, ISetUntilDate } from "../../../../actions/actions";

export interface IDesktopCalendarPickerProps
  extends ConnectedDesktopCalendarPicker {
  isOpen: boolean;
  closePopup: () => void;
  tripCategory?: TripCategory;
  departureProp?: Date | null;
  returnProp?: Date | null;
  onClickDone?: () => void;
  saveDatesOnClose?: boolean;
  fromDate: Date | null;
  untilDate: Date | null;
  setFromDate: (date: Date | null) => ISetFromDate;
  setUntilDate: (date: Date | null) => ISetUntilDate;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  headerTitle?: JSX.Element | string;
}

export const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    isOpen,
    closePopup,
    fromDate,
    untilDate,
    setFromDate,
    setUntilDate,
    onClickDone,
    saveDatesOnClose,
    headerTitle,
    minAllowedDate,
    maxAllowedDate,
  } = props;

  const calendarHeaderTitle = headerTitle || DESKTOP_CALENDAR_TITLE_RANGE;

  const dispatch = useDispatch();

  const [from, setFrom] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  const handleClickDone = () => {
    closePopup();
    dispatch(setFromDate(from));
    dispatch(setUntilDate(until));
    onClickDone && onClickDone();
  };

  const handleClose = () => {
    closePopup();
    if (!saveDatesOnClose) {
      setFrom(fromDate);
      setUntil(untilDate);
    }
  };

  return (
    <DesktopPopupModal
      open={isOpen}
      onClose={handleClose}
      className={clsx(
        "desktop-calendar-picker-popup-root",
        "desktop-experiences-calendar-picker-popup-root",
        "experiences-module"
      )}
      contentClassName="desktop-calendar-picker-wrapper"
      invisibleBackdrop={false}
    >
      <MonthAndDatePicker
        viewType={MonthAndDatePickerType.Horizontal}
        startDate={from}
        endDate={until}
        setStartDate={(from: Date | null) => {
          setFrom(from);
        }}
        setEndDate={(until: Date | null) => {
          setUntil(until);
        }}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
        header={calendarHeaderTitle}
        className="b2b-flights"
      />
      <Button
        onClick={handleClickDone}
        disabled={!from || !until}
        className="select-dates-button"
        variant="contained"
      >
        {textConstants.DONE_BUTTON_TEXT}
      </Button>
    </DesktopPopupModal>
  );
};
