import React, { useEffect, useContext, useState, useMemo } from "react";
import {
  ITravelOfferCardContent,
  TravelWalletDetailsBanner,
  getTravelOfferCardContent,
  getTravelCreditCardContent,
  TravelCreditDetailsCardContent,
  IconName,
  Icon,
  getTravelCreditHistoryModalProps,
  useDeviceTypes,
  isOfferHSPTiered,
} from "halifax";

import {
  SelectedTravelOfferProperties,
  TravelWalletOffer,
  SelectedTravelOfferScreen,
} from "redmond";
import {
  getTravelOfferBannerText,
  VIEW_OFFER_DETAILS_CTA_COPY,
  VIEW_DETAILS_CTA_COPY,
  getTravelCreditBannerText,
  CREDITS_MODAL_HEADER_TEXT,
} from "./textConstants";
import { RouteComponentProps } from "react-router-dom";
import { TravelWalletDetailsBannerConnectorProps } from "./container";
import { ClientContext } from "../../../../App";
import "./styles.scss";
import {
  useExperiments,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  ANNUAL_TRAVEL_CREDITS,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
} from "../../../../context/experiments";
import { HSP_OFFER_TITLE_LABEL } from "../TravelWalletDrawer/constants";
import { config } from "../../../../api/config";

interface IExperiencesTravelWalletDetailsBannerProps
  extends TravelWalletDetailsBannerConnectorProps,
    RouteComponentProps {
  onDismiss?: () => void;
  offer?: TravelWalletOffer;
  bannerTextType: "availability" | "shop";
  showButton?: boolean;
  className?: string;
  screen?: SelectedTravelOfferProperties["screen"];
  showOffer?: boolean;
}

export const ExperiencesTravelWalletDetailsBanner = (
  props: IExperiencesTravelWalletDetailsBannerProps
) => {
  const {
    offer,
    onDismiss,
    showButton,
    className,
    screen,
    creditBreakdown,
    credit,
    bannerTextType,
    creditsTransactionHistory,
    rewardsAccounts,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const [openModal, setOpenModal] = useState(false);
  const [bestOffer, setBestOffer] = useState(offer);
  const [
    travelWalletDetailsBannerModalContent,
    setTravelWalletDetailsBannerModalContent,
  ] = useState<ITravelOfferCardContent | TravelCreditDetailsCardContent | null>(
    null
  );
  const [travelWalletDetailsBannerText, setTravelWalletDetailsBannerText] =
    useState("");
  const [isCredit, setIsCredit] = useState(false);

  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  useEffect(() => {
    if (offer && bestOffer !== offer) {
      setBestOffer(offer);
    }
    if (credit && isTravelWalletCreditsExperiment) {
      // offer as a prop comes from /availability as the best offer so that amount is the same as the credit amount, then we know the best offer we get back is credit (so we display credit text)
      const bestOfferIsACredit =
        !bestOffer ||
        Math.abs(credit.amount.amount) === Math.abs(bestOffer.amount.amount)
          ? true
          : false;
      setIsCredit(bestOfferIsACredit);
    } else {
      setIsCredit(false);
    }
  }, [offer, credit]);

  useEffect(() => {
    const content =
      isCredit && credit
        ? getTravelCreditCardContent(
            creditBreakdown,
            config.TENANT,
            firstName,
            credit.amount,
            isCreditAndOfferStackingExperimentV1
          )
        : bestOffer
        ? getTravelOfferCardContent(
            bestOffer,
            isCreditAndOfferStackingExperimentV1
          )
        : undefined;
    setTravelWalletDetailsBannerModalContent(content);
    const text =
      isCredit && credit
        ? getTravelCreditBannerText(credit)
        : bestOffer
        ? getTravelOfferBannerText(bestOffer, bannerTextType)
        : undefined;
    text && setTravelWalletDetailsBannerText(text);
  }, [isCredit]);

  if ((!bestOffer && !credit) || !travelWalletDetailsBannerText) return null;
  return (
    <>
      {isCredit ? (
        <TravelWalletDetailsBanner
          className={className}
          openModal={openModal}
          onDismissClick={onDismiss}
          setOpenModal={(open) => {
            setOpenModal(open);
          }}
          travelOfferBannerText={travelWalletDetailsBannerText}
          modalHeaderText={CREDITS_MODAL_HEADER_TEXT(firstName)}
          modalCTACopy={VIEW_DETAILS_CTA_COPY}
          showButton={showButton}
          onFirstTravelOfferCardChange={() => {}}
          creditDetailsCardContent={travelWalletDetailsBannerModalContent}
          modalVariant="credit"
          icon={<Icon name={IconName.PiggyBank} />}
          showCreditHistory={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
          }
          travelCreditHistoryProps={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
              ? {
                  ...getTravelCreditHistoryModalProps(
                    firstName,
                    creditsTransactionHistory,
                    credit,
                    !matchesMobile ? IconName.PiggyBankCircleOutline : undefined
                  ),
                  rewardsAccounts,
                  credit,
                  isAnnualTravelCreditsExperiment,
                }
              : undefined
          }
          isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
          onViewCreditHistory={() => {}}
        />
      ) : (
        <TravelWalletDetailsBanner
          className={className}
          openModal={openModal}
          onDismissClick={
            screen === SelectedTravelOfferScreen.EXPERIENCES_SHOP &&
            !matchesMobile
              ? onDismiss
              : undefined
          }
          setOpenModal={(open) => {
            setOpenModal(open);
          }}
          travelOfferCardContent={travelWalletDetailsBannerModalContent}
          travelOfferBannerText={travelWalletDetailsBannerText}
          modalCTACopy={
            screen === SelectedTravelOfferScreen.EXPERIENCES_SHOP
              ? VIEW_DETAILS_CTA_COPY
              : VIEW_OFFER_DETAILS_CTA_COPY
          }
          showButton={showButton}
          onFirstTravelOfferCardChange={() => {}}
          offerFunnels={offer?.funnels}
          offerLocation={offer?.descriptions[2]}
          isTravelSale={isTravelSaleEnabled}
          imageId={offer?.imageId}
          modalTitleLabelText={
            offer && isOfferHSPTiered(offer.descriptions)
              ? HSP_OFFER_TITLE_LABEL
              : undefined
          }
        />
      )}
    </>
  );
};
