import {
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilityResponse,
  ICategorizedResponse,
  IResult,
} from "redmond";
import * as H from "history";
import * as actionTypes from "./constants";

export interface IFetchExperiencesAvailability {
  type:
    | actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY
    | actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY;
  requestType: ExperiencesAvailabilityRequestEnum;
  history: H.History;
}

export const fetchInitialExperiencesAvailability = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
  requestType: ExperiencesAvailabilityRequestEnum.InitialSearch,
  history,
});

export const fetchMoreExperiencesAvailability = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY,
  requestType: ExperiencesAvailabilityRequestEnum.FollowupSearch,
  history,
});

export interface IStopFetchMoreExperiencesAvailability {
  type: actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY;
}

export const stopFetchMoreExperiencesAvailability =
  (): IStopFetchMoreExperiencesAvailability => ({
    type: actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY,
  });

export interface ISetExperiencesAvailabilityResults {
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS;
  payload: ExperiencesAvailabilityResponse;
  responseType: ExperiencesAvailabilityRequestEnum;
}

export const setExperiencesAvailabilityResults = (args: {
  payload: ExperiencesAvailabilityResponse;
  responseType: ExperiencesAvailabilityRequestEnum;
}): ISetExperiencesAvailabilityResults => ({
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS,
  ...args,
});

export interface ISetExperiencesAvailabilityCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED;
}

export const setExperiencesAvailabilityCallStateFailed =
  (): ISetExperiencesAvailabilityCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface IFetchKeywordCategories {
  type: actionTypes.FETCH_KEYWORD_CATEGORIES;
  queryString: string;
}

export const fetchKeywordCategories = (
  queryString: string
): IFetchKeywordCategories => ({
  type: actionTypes.FETCH_KEYWORD_CATEGORIES,
  queryString,
});

export interface ISetKeywordCategories {
  type: actionTypes.SET_KEYWORD_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setKeywordCategories = (
  categories: ICategorizedResponse[]
): ISetKeywordCategories => ({
  type: actionTypes.SET_KEYWORD_CATEGORIES,
  categories,
});

export interface ISetKeyword {
  type: actionTypes.SET_KEYWORD;
  keyword: IResult | null;
}

export const setKeyword = (keyword: IResult | null): ISetKeyword => ({
  type: actionTypes.SET_KEYWORD,
  keyword,
});

export interface ISetKeywordAutocompleteError {
  type: actionTypes.SET_KEYWORD_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setKeywordAutocompleteError = (
  error: boolean
): ISetKeywordAutocompleteError => ({
  type: actionTypes.SET_KEYWORD_AUTOCOMPLETE_ERROR,
  error,
});

export type ExperiencesAvailabilityActions =
  | IFetchExperiencesAvailability
  | IStopFetchMoreExperiencesAvailability
  | ISetExperiencesAvailabilityResults
  | ISetExperiencesAvailabilityCallStateFailed
  | IFetchKeywordCategories
  | ISetKeywordCategories
  | ISetKeyword
  | ISetKeywordAutocompleteError;
