import { put } from "redux-saga/effects";
import { WalletHistoryResponse } from "redmond";
import { actions } from "../actions";
import { fetchTravelWalletCreditHistory } from "../../../api/v0/travel-wallet/fetchTravelWalletCreditHistory";

export function* fetchTravelWalletCreditHistorySaga(
  _: actions.IFetchTravelWalletCreditHistory
) {
  try {
    const walletCreditHistory: WalletHistoryResponse =
      yield fetchTravelWalletCreditHistory();
    yield put(
      actions.setTravelWalletCreditHistory(walletCreditHistory.credits)
    );
    yield put(actions.fetchTravelWalletCreditHistoryCallStateSuccess());
  } catch (e) {
    yield put(actions.fetchTravelWalletCreditHistoryCallStateFailure());
  }
}
