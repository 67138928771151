import React, { useState } from "react";
import { DatePickerButton } from "halifax";
import { DesktopCalendarPicker } from "../DesktopCalendarPicker";
import { actions } from "../../../../actions";
import * as textConstants from "../../textConstants";
import "./styles.scss";

export interface ICalendarPickerButtonProps {
  classes?: string[];
  onClick?: () => void;
  disabled?: boolean;
  saveDatesOnClose?: boolean;
  hasMissingSearchInfoError?: boolean;
  fromDate: Date | null;
  untilDate: Date | null;
  setFromDate: (date: Date | null) => actions.ISetFromDate;
  setUntilDate: (date: Date | null) => actions.ISetUntilDate;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  headerTitle?: JSX.Element | string;
}

export const CalendarPickerButton = ({
  classes = [],
  fromDate,
  untilDate,
  disabled,
  saveDatesOnClose,
  hasMissingSearchInfoError,
  setFromDate,
  setUntilDate,
  headerTitle,
  onClick: onButtonClick,
  minAllowedDate,
  maxAllowedDate,
}: ICalendarPickerButtonProps) => {
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);

  const onClick = () => {
    if (!disabled) {
      onButtonClick && onButtonClick();
      setIsDatesModalOpen(true);
    }
  };

  return (
    <DatePickerButton
      startDate={fromDate}
      startLabel={textConstants.SEARCH_DATES_LABEL}
      endDate={untilDate}
      endLabel={""}
      classes={classes}
      variant="combinedDates"
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
          fromDate={fromDate}
          untilDate={untilDate}
          setFromDate={setFromDate}
          setUntilDate={setUntilDate}
          isOpen={isDatesModalOpen}
          closePopup={() => setIsDatesModalOpen(false)}
          saveDatesOnClose={saveDatesOnClose}
          headerTitle={headerTitle}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd, MMM D"}
      hideEndDate={true}
      hideSeparator={true}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
