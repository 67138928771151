import { call, race, take, takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchExperiencesAvailabilitySaga } from "./fetchExperiencesAvailabilitySaga";
import { IFetchExperiencesAvailability } from "../actions/actions";

export function* watchFetchExperiencesAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
    fetchExperiencesAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY,
    function* (action: IFetchExperiencesAvailability) {
      yield race({
        task: call(fetchExperiencesAvailabilitySaga, action),
        cancel: take([
          actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY,
          actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
        ]),
      });
    }
  );
}
