import React, { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  ActionButton,
  InfoIcon,
  Icon,
  IconName,
  RecentlySearchedList,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICategorizedResponse, IResult, RecentHotelSearch } from "redmond";

export const LOADING_TEXT = "Loading";
export const CONTINUE = "Continue";

export interface ILocationSearchProps {
  location: IResult | null;
  locationCategories: ICategorizedResponse[];
  loading: boolean;
  hasLocationAutocompleteError: boolean;
  setLocation: (location: IResult | null) => void;
  fetchLocationCategories: (queryString: string) => void;
  setLocationAutocompleteError: (error: boolean) => void;
  onComplete?: (value?: any) => void;
  onEditDates?: boolean;
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onClickAutocompleteError?: () => void;
  locationSearchPrefixString: string;
}

export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    location,
    loading,
    onComplete,
    setLocation,
    fetchLocationCategories,
    locationCategories,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    recentSearches,
    onRecentSearchClick,
    onClickAutocompleteError,
    locationSearchPrefixString,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>(
    location?.label || ""
  );
  const [labelToDisplay, setLabelToDisplay] = useState<string>(
    locationSearchPrefixString
  );

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasLocationAutocompleteError && setLocationAutocompleteError(false);

    setLocation(null);

    setLocationSearchString(event.target.value);
    if (event.target.value) {
      fetchLocationCategories(event.target.value);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);
    setLocation(value);
    onContinue(value);
  };

  const onContinue = (value?: any) => {
    if (onComplete) {
      onComplete(value);
    }
  };

  const handleFocus = () => {
    setLabelToDisplay(locationSearchPrefixString);
  };

  const handleBlur = () => {
    setLabelToDisplay(locationSearchPrefixString);
  };

  const onClearLocationField = () => {
    setLocation(null);
    setLocationSearchString("");
    fetchLocationCategories("");
  };

  return (
    <Box className="mobile-search-location">
      <Box className="mobile-location-pickers">
        <Box className="mobile-search-location-input-field">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className={clsx("origin-auto-complete", "mobile")}
            onChange={(value) => onLocationSearch(value)}
            value={locationSearchString}
            label={labelToDisplay}
            InputProps={{
              disableUnderline: true,
            }}
            onFocus={handleFocus}
            onBlur={!locationSearchString ? handleBlur : undefined}
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !locationSearchString })}
            icon={faTimesCircle}
            onClick={onClearLocationField}
          />
        </Box>
        {hasLocationAutocompleteError && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span onClick={onClickAutocompleteError} className="try-again-text">
              Try again.
            </span>
          </Box>
        )}
      </Box>

      <MobileAutocompleteOptions
        value={null}
        valueCategories={locationCategories}
        setValue={(value: any) => onLocationSelected(value)}
        loading={loading}
        loadingText={LOADING_TEXT}
      />
      {!loading &&
        locationSearchString.length === 0 &&
        (locationCategories.length === 0 ||
          locationCategories[0]?.results.length === 0) &&
        !!recentSearches &&
        recentSearches.length > 0 &&
        !!onRecentSearchClick && (
          <div
            className={clsx("mobile-recently-searched-hotels-container", {
              "has-results": locationCategories.length > 0,
            })}
          >
            <RecentlySearchedList
              recentSearches={recentSearches}
              onRecentSearchClick={(recentSearch) =>
                onRecentSearchClick(recentSearch as RecentHotelSearch)
              }
            />
          </div>
        )}
      {!!location && (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={CONTINUE}
          onClick={() => {
            onContinue(location);
          }}
        />
      )}
    </Box>
  );
};
