import { all } from "redux-saga/effects";
import { sagas as experiencesRewards } from "../modules/rewards";
import { sagas as experiencesSearch } from "../modules/search";
import { sagas as experiencesAvailability } from "../modules/availability";
import { sagas as experiencesTravelWallet } from "../modules/travel-wallet";

export function* rootSaga() {
  yield all([
    experiencesRewards.watchFetchRewardsAccounts(),

    experiencesSearch.watchFetchLocationCategories(),

    experiencesAvailability.watchFetchExperiencesAvailability(),

    experiencesTravelWallet.watchFetchTravelWalletDetails(),
    experiencesTravelWallet.watchFetchTravelWalletCreditHistorySaga(),
  ]);
}
