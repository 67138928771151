import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "./styles.scss";
import H from "history";
import {
  ExperienceAvailability,
  GroupedExperienceIds,
  RewardsAccount,
} from "redmond";
import {
  BackButton,
  ExperiencesAvailabilityCard,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { getEarnTagText } from "../../textConstants";

export interface IExperiencesCategoryRowProps {
  history: H.History;
  experiencesMap: Map<string, ExperienceAvailability>;
  experienceByCategory: GroupedExperienceIds;
  largestValueAccount?: RewardsAccount;
  rewardsKey?: string;
}

export const ExperiencesCategoryRow = (props: IExperiencesCategoryRowProps) => {
  const {
    experiencesMap,
    experienceByCategory,
    rewardsKey,
    largestValueAccount,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const currentCategoryName = experienceByCategory.tag.name;
  const currentCategoryId = experienceByCategory.tag.id;

  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const determineOverFlow = () => {
    const overFlowedElement = document.getElementById("hotel-shop-room-picker");
    if (overFlowedElement) {
      return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
    }
    return false;
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(
      `experiences-category-${currentCategoryId}`
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };
  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(
      `experiences-category-${currentCategoryId}`
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  // TODO: Remove this, it's just for showing images temporarily
  const imageArr = [
    "https://hopper-partner-cdn.hopper.com/1/681c0497-7dc8-44d5-9855-9292243f1fbb/2b181be4-2e2c-3561-b1c3-ba76a07c854a.png",
    "https://hopper-partner-cdn.hopper.com/1/8460d62a-d033-44a4-ab74-de03595c7fea/6815242a-ba2f-3af9-a0e8-6dea730a2247.png",
    "https://hopper-partner-cdn.hopper.com/1/f70fb13a-5084-449f-a2d6-a6759b00b4d7/8f75411b-70a3-36e7-8100-e0a931ea2a56.png",
    "https://hopper-partner-cdn.hopper.com/1/f4e869ee-61fd-421b-b60e-4da6dfbe724f/ae7a6aa7-548e-36da-8e92-61a74ec1a837.png",
    "https://hopper-partner-cdn.hopper.com/1/4a272a10-525c-40ca-aa74-8ad7abcb01be/f65c1d0e-76f4-3e7f-b619-e920785b5d8e.png",
  ];
  return (
    <Box className="experiences-category-row-root">
      <Box className={clsx("experiences-category-row-title-container")}>
        <Typography
          variant="h3"
          className={clsx("experiences-category-row-title")}
        >
          {`${experienceByCategory.tag.name} (${experienceByCategory.experienceIds.length})`}
        </Typography>
      </Box>
      <Box className={clsx("experiences-category-row-content")}>
        {!matchesMobile && isOverFlow && (
          <BackButton
            aria-label={`See more experiences in ${currentCategoryName}`}
            onClick={leftScrollButton}
            className="left-scroll-button"
          />
        )}
        <Box
          className="experiences-category-wrapper"
          id={`experiences-category-${currentCategoryId}`}
        >
          {experienceByCategory.experienceIds.map((experienceId) => {
            let experienceAvailabilityInfo = experiencesMap.get(
              experienceId.value
            );

            if (experienceAvailabilityInfo) {
              // TODO: Remove this it's to deal with mock data
              experienceAvailabilityInfo["coverImage"] =
                imageArr[Math.floor(Math.random() * (4 - 0 + 1) + 0)];
              experienceAvailabilityInfo.rating.reviewAverage = Math.floor(
                Math.random() * (4 - 0 + 1) + 0
              );
              /////
              return (
                <ExperiencesAvailabilityCard
                  experiencesAvailabilityInfo={experienceAvailabilityInfo}
                  variant="card"
                  rewardsKey={rewardsKey}
                  earnTagContent={
                    largestValueAccount && (
                      <>
                        <Icon name={IconName.StarIcon} />
                        <Typography
                          className="earn-tag-text"
                          dangerouslySetInnerHTML={{
                            __html: getEarnTagText(
                              // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE https://hopper-jira.atlassian.net/browse/COTA-1548
                              largestValueAccount.earn.hotelsMultiplier,
                              largestValueAccount.rewardsBalance
                                .currencyDescription ??
                                largestValueAccount.rewardsBalance.currency
                            ),
                          }}
                        />
                      </>
                    )
                  }
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
        {!matchesMobile && isOverFlow && (
          <BackButton
            aria-label={`See more experiences in ${currentCategoryName}`}
            onClick={rightScrollButton}
            className="right-scroll-button"
          />
        )}
      </Box>
    </Box>
  );
};
