import { LocationQueryEnum, ILocationQueryLabel } from "redmond";
import { delay, put } from "redux-saga/effects";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import Logger from "../../../utils/logger";
import { actions } from "../actions";

export function* fetchLocations(action: actions.IFetchLocationCategories) {
  yield delay(300);
  try {
    const requestBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: action.queryString,
    };

    const { categories } = yield fetchLocationAutocomplete(requestBody);
    yield put(actions.setLocationCategories(categories));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setLocationCategories([]));
    yield put(actions.setLocationAutocompleteError(true));
  }
}
