import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getHasLocationAutocompleteError,
  getLocation,
  getLocationCategories,
  getLocationCategoriesLoading,
} from "../../reducer";
import { MobileExperiencesSearchControl } from "./component";
import { actions } from "../../actions";

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  fetchLocationCategories: actions.fetchLocationCategories,
  setLocationAutocompleteError: actions.setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getLocation(state),
  locationCategories: getLocationCategories(state),
  loading: !!getLocationCategoriesLoading(state),
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileExperiencesSearchConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileExperiencesSearchControl = withRouter(
  connector(MobileExperiencesSearchControl)
);
