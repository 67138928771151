import React, { ReactNode } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export interface AvailabilitySplitViewProps {
  left: ReactNode;
  right: ReactNode;
  className: string;
  leftClassName?: string;
  rightClassName?: string;
}

export const AvailabilitySplitView = (
  props: AvailabilitySplitViewProps
): JSX.Element => {
  const { left, leftClassName, right, rightClassName, className } = props;
  return (
    <Box className={clsx("experiences-split-components-container", className)}>
      <Box className={clsx("experiences-split-components-section")}>
        <Box className={clsx("left-section", leftClassName)}>{left}</Box>
        <Box className={clsx("right-section", rightClassName)}>{right}</Box>
      </Box>
    </Box>
  );
};
