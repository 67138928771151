import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ExperiencesTravelCreditBalanceCardConnectorProps } from "./container";
import "./styles.scss";
import {
  getTravelCreditHistoryModalProps,
  TravelCreditBalanceCard,
} from "halifax";
import { ClientContext } from "../../../../App";

import {
  CREDIT_BALANCE_APPLICABILITY_TEXT,
  CREDIT_BALANCE_DESCRIPTION,
  CREDIT_BALANCE_TEXT,
} from "./textConstants";

export interface IExperiencesTravelCreditBalanceCardProps
  extends ExperiencesTravelCreditBalanceCardConnectorProps,
    RouteComponentProps {
  isTravelCreditHistoryExperiment?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
  isMobile?: boolean;
  onViewCreditHistory?: () => void;
}

export const ExperiencesTravelCreditBalanceCard = ({
  credit,
  creditBreakdown,
  creditsTransactionHistory,
  isTravelCreditHistoryExperiment,
  isAnnualTravelCreditsExperiment,
  isMobile,
  rewardsAccounts,
  onViewCreditHistory,
}: IExperiencesTravelCreditBalanceCardProps) => {
  const firstName =
    React.useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  return (
    <TravelCreditBalanceCard
      description={CREDIT_BALANCE_DESCRIPTION(firstName)}
      balanceText={CREDIT_BALANCE_TEXT(credit?.amount)}
      applicabilityText={CREDIT_BALANCE_APPLICABILITY_TEXT}
      showCreditHistory={
        isTravelCreditHistoryExperiment && !!creditsTransactionHistory?.length
      }
      travelCreditHistoryProps={
        isTravelCreditHistoryExperiment && !!creditsTransactionHistory?.length
          ? getTravelCreditHistoryModalProps(
              firstName,
              creditsTransactionHistory,
              credit
            )
          : undefined
      }
      isMobile={isMobile}
      credit={credit}
      creditBreakdown={creditBreakdown}
      rewardsAccounts={rewardsAccounts}
      isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
      onViewCreditHistory={onViewCreditHistory}
    />
  );
};
