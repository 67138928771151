import React from "react";
import { CallState, RewardsAccount } from "redmond";
import { Skeleton } from "@material-ui/lab";

export const SEARCH_EXPERIENCES_BUTTON = "Search experiences";

export const SUBTITLE = (
  <>Book tours and activities you’ll love with elevated travel benefits.</>
);

export const getEarnMessageToDisplay = (
  fetchRewardsAccountsCallState: CallState,
  largestValueAccount?: RewardsAccount
) => {
  if (
    (fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed) &&
    largestValueAccount
  ) {
    return EARN_MESSAGE(largestValueAccount);
  } else {
    return <Skeleton className="title-loading" />;
  }
};

export const EARN_MESSAGE = (rewardsAccount: RewardsAccount) => {
  const earnSymbol =
    rewardsAccount.rewardsBalance.currency === "Cash" ? "%" : "x";

  // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE
  //  https://hopper-jira.atlassian.net/browse/COTA-1548
  const { experiencesMultiplier, hotelsMultiplier } = rewardsAccount.earn;
  const earnMultiplier = experiencesMultiplier
    ? experiencesMultiplier
    : hotelsMultiplier;
  const { currencyDescription } = rewardsAccount.rewardsBalance;

  const experiencesEarnString = `${earnMultiplier}${earnSymbol} ${currencyDescription} on experiences`;

  const funnelEarnText = (() => {
    return experiencesEarnString;
  })();

  return (
    <span className="font-regular">
      Earn <strong>{funnelEarnText}</strong> with{" "}
      {rewardsAccount.productDisplayName} account.
    </span>
  );
};
