import { connect } from "react-redux";
import { IResult } from "redmond";
import {
  getKeyword,
  getKeywordCategories,
  getKeywordCategoriesLoading,
} from "../../reducer";
import { actions } from "../../actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import { IStoreState } from "../../../../reducers/types";

export const mapDispatchToProps = {
  setValue: actions.setKeyword,
  fetchValueCategories: actions.fetchKeywordCategories,
};

export const mapStateToProps = (state: IStoreState) => {
  let loading = !!getKeywordCategoriesLoading(state);

  return {
    overrideInputValue: undefined,
    value: getKeyword(state),
    valueCategories: getKeywordCategories(state),
    loading,
  };
};

export const ConnectedKeywordAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
