import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { RewardsBanner } from "./component";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import { getRewardsAccountWithLargestEarnForBanner } from "../../reducer";

// TODO: Update banner content with user data
const mapStateToProps = (state: IStoreState) => {
  return {
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

const mapDispatchToProps = { fetchRewardsAccounts };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRewardsBanner = withRouter(connector(RewardsBanner));
