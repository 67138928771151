import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "lodash-es";
import {
  getLocation,
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";
import { IResult } from "redmond/build/common";
import { ExperienceAvailability, GroupedExperienceIds } from "redmond";

export const getExperiencesAvailabilityCallState = (state: IStoreState) =>
  state.experiencesAvailability.experiencesAvailabilityCallState;

export const getExperiencesAvailabiliyResponse = (state: IStoreState) =>
  state.experiencesAvailability.availabilityResponse;

export const getExperiencesAvailabilityNextPageToken = createSelector(
  getExperiencesAvailabiliyResponse,
  (availabilityResponse) => {
    return availabilityResponse?.nextPageToken;
  }
);

export const getExperiences = createSelector(
  getExperiencesAvailabiliyResponse,
  (availabilityResponse): ExperienceAvailability[] => {
    if (!availabilityResponse) {
      return [];
    }
    return availabilityResponse.experiences;
  }
);

export const getExperiencesMap = createSelector(
  getExperiences,
  (experiences) => {
    let experiencesMap = new Map();
    experiences.map((experience) => {
      experiencesMap.set(experience.id.value, experience);
    });

    return experiencesMap;
  }
);

export const getExperiencesByCategory = createSelector(
  getExperiencesAvailabiliyResponse,
  (availabilityResponse): GroupedExperienceIds[] => {
    if (!availabilityResponse) {
      return [];
    }

    return availabilityResponse.experiencesByCategory;
  }
);

// TODO: Remove experiencesSearch selectors, we should be using the ones that exist in the other modules
export const getExperiencesAvailabilitySearchLocation = (state: IStoreState) =>
  state.experiencesSearch.location;

export const getExperiencesAvailabilityFromDate = (state: IStoreState) =>
  state.experiencesSearch.fromDate;

export const getExperiencesAvailabilityUntilDate = (state: IStoreState) =>
  state.experiencesSearch.untilDate;

export const getExistingExperiencesAvailabilityRequestParameters =
  createSelector(
    getExperiencesAvailabilitySearchLocation,
    getExperiencesAvailabilityFromDate,
    getExperiencesAvailabilityUntilDate,

    (
      location,
      departureDate,
      returnDate
    ): {
      location: IResult | null;
      departureDate: Date | null;
      returnDate: Date | null;
    } => ({
      location,
      departureDate,
      returnDate,
    })
  );

export const areRequestParametersChanged = createSelector(
  getExistingExperiencesAvailabilityRequestParameters,
  getLocation,
  getFromDate,
  getUntilDate,
  (
    requestParameters,
    locationSearched,
    fromDateSearched,
    untilDateSearched
  ): boolean => {
    return (
      (locationSearched !== null &&
        !isEqual(locationSearched, requestParameters.location)) ||
      !isEqual(fromDateSearched, requestParameters.departureDate) ||
      !isEqual(untilDateSearched, requestParameters.returnDate)
    );
  }
);

export const getExperiencesQueryParams = createSelector(
  getExperiencesAvailabilityFromDate,
  getExperiencesAvailabilityUntilDate,
  (fromDate, untilDate) => ({
    fromDate,
    untilDate,
  })
);

export const getKeyword = (state: IStoreState): IResult | null =>
  state.experiencesAvailability.keyword;

export const getKeywordCategories = (state: IStoreState) =>
  state.experiencesAvailability.keywordCategories;

export const getKeywordCategoriesLoading = (state: IStoreState) =>
  state.experiencesAvailability.keywordCategoriesLoading;

export const getHasKeywordAutocompleteError = (state: IStoreState) =>
  state.experiencesAvailability.hasKeywordAutocompleteError;
