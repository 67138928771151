import { connect, ConnectedProps } from "react-redux";

import { KeywordSearch } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getLocation } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    location: getLocation(state),
  };
};

const mapDispatchToProps = () => {
  return {
    resetFilters: () => {},
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type KeywordSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedKeywordSearch = connector(KeywordSearch);
