import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../reducers/types";
import { withRouter } from "react-router";
import { ExperiencesSearch } from "./component";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
} from "../rewards/reducer";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import { setLocation } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
});

const mapDispatchToProps = {
  fetchRewardsAccounts,
  setLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedExperiencesSearch = withRouter(
  connector(ExperiencesSearch)
);
