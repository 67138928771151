import { actions, actionTypes } from "../actions";
import { ICategorizedResponse, IResult } from "redmond";

export interface IExperiencesSearchState {
  location: IResult | null;
  locationCategories: ICategorizedResponse[];
  locationCategoriesLoading: boolean | null;
  hasLocationAutocompleteError: boolean;
  fromDate: Date | null;
  untilDate: Date | null;
}

export const initialState: IExperiencesSearchState = {
  location: null,
  locationCategories: [],
  locationCategoriesLoading: false,
  hasLocationAutocompleteError: false,
  fromDate: null,
  untilDate: null,
};

export function reducer(
  state: IExperiencesSearchState = initialState,
  action: actions.ExperiencesSearchActions
): IExperiencesSearchState {
  switch (action.type) {
    case actionTypes.SET_LOCATION:
      const { location } = action;
      return {
        ...state,
        location: location ? { ...location } : null,
        locationCategoriesLoading: false,
        hasLocationAutocompleteError: false,
      };

    case actionTypes.SET_LOCATION_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        locationCategories: categories,
        locationCategoriesLoading: false,
      };

    case actionTypes.FETCH_LOCATION_CATEGORIES:
      return {
        ...state,
        locationCategories: [],
        locationCategoriesLoading: true,
      };

    case actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasLocationAutocompleteError: action.error,
        locationCategoriesLoading: false,
      };
    }

    case actionTypes.SET_FROM_DATE:
      return { ...state, fromDate: action.date };

    case actionTypes.SET_UNTIL_DATE:
      return {
        ...state,
        untilDate: action.date,
      };

    default:
      return state;
  }
}

export * from "./selectors";
