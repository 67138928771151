import { put } from "redux-saga/effects";
import { WalletDetailsResponse } from "redmond";
import { actions } from "../../actions";
import { fetchTravelWalletDetails } from "../../../../api/v0/travel-wallet/fetchTravelWalletDetails";

export function* fetchTravelWalletDetailsSaga(
  _: actions.IFetchTravelOffersDetails
) {
  try {
    const walletDetails: WalletDetailsResponse =
      yield fetchTravelWalletDetails();
    yield put(actions.setTravelWalletOffers(walletDetails.offers));
    yield put(actions.setTravelWalletCredit(walletDetails.creditBreakdown, walletDetails.credit));
  } catch (e) {
    yield put(actions.fetchTravelWalletDetailsCallStateFailed());
  }
}
