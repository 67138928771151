import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DateRangePicker, PickerType } from "halifax";
import dayjs from "dayjs";

import { MonthAndDatePickerConnectorProps } from "./container";
import "./styles.scss";

export interface IMonthAndDatePickerProps
  extends MonthAndDatePickerConnectorProps {
  pickerType: PickerType;
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  className?: string;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    pickerType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    viewType,
    header,
    focusedMonthIndex,
    setFocusedMonthIndex,
    className,
    minAllowedDate,
    maxAllowedDate,
  } = props;

  return (
    <>
      {header && (
        <Box className="month-and-date-picker-header-container">
          {typeof header === "string" ? (
            <Typography variant="body2">{header}</Typography>
          ) : (
            header
          )}
        </Box>
      )}
      <DateRangePicker
        className={className}
        minAllowedDate={minAllowedDate || dayjs().toDate()}
        maxAllowedDate={maxAllowedDate || dayjs().add(1, "year").toDate()}
        months={[]}
        priceTags={[]}
        currency={""}
        startDate={startDate}
        endDate={endDate}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
      />
    </>
  );
};

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}
