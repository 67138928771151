import { ExperiencesEntryTypeEnum } from "redmond";
import dayjs from "dayjs";

export const transformToStringifiedAvailabilityQuery = (
  location: string,
  fromDate: Date | null,
  untilDate: Date | null,
  entryPoint?: ExperiencesEntryTypeEnum
): string => {
  location = encodeURIComponent(location);

  let query = `?locationName=${location}`;

  if (fromDate) {
    const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
    query += `&fromDate=${formatFrom}`;
  }

  if (untilDate) {
    const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");
    query += `&untilDate=${formatUntil}`;
  }

  if (entryPoint) {
    query += `&entryPoint=${entryPoint}`;
  }
  return query;
};
