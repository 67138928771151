import {
  ExperiencesAvailabilityRequest,
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilitySort,
  ExperiencesAvailabilityResponse,
  ExperiencesSelection,
  IIdExperiences,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import { fetchExperiencesAvailability } from "../../../api/v0/availability/fetchExperiencesAvailability";
import Logger from "../../../utils/logger";
import dayjs from "dayjs";
import { getFromDate, getLocation, getUntilDate } from "../../search/reducer";
import { getExperiencesAvailabilityNextPageToken } from "../reducer";

export function* fetchExperiencesAvailabilitySaga(
  action: actions.IFetchExperiencesAvailability
) {
  try {
    let requestBody: ExperiencesAvailabilityRequest;
    const state: IStoreState = yield select();

    // TODO - COTA-1537 : Once we have filtering/sorting this would need to accept those too
    const requestData = {
      fromDate: getFromDate(state),
      untilDate: getUntilDate(state),
      experienceSelection: (getLocation(state)?.id as IIdExperiences)
        .experiencesSelection,
    };

    const { fromDate, untilDate, experienceSelection } = requestData;

    switch (action.requestType) {
      case ExperiencesAvailabilityRequestEnum.InitialSearch: {
        if (JSON.stringify(requestData) === "{}") return;
        requestBody = {
          AvailabilityRequest: ExperiencesAvailabilityRequestEnum.InitialSearch,
          dateRange: {
            from: dayjs(fromDate).format("YYYY-MM-DD"),
            until: dayjs(untilDate).format("YYYY-MM-DD"),
          },
          experiencesSelection: experienceSelection as ExperiencesSelection,
          // TODO - COTA-1537 : Once we have filtering/sorting this would need to accept those too
          refinement: {
            sort: ExperiencesAvailabilitySort.Recommended,
            tags: [],
          },
        };

        break;
      }
      case ExperiencesAvailabilityRequestEnum.FollowupSearch: {
        const nextPageToken = getExperiencesAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          nextPageToken: nextPageToken,
          AvailabilityRequest:
            ExperiencesAvailabilityRequestEnum.FollowupSearch,
        };

        break;
      }
    }
    let availabilityResponse: ExperiencesAvailabilityResponse =
      yield fetchExperiencesAvailability(requestBody);
    yield put(
      actions.setExperiencesAvailabilityResults({
        payload: availabilityResponse,
        responseType: action.requestType,
      })
    );
  } catch (e) {
    yield put(actions.setExperiencesAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}
