import { IStoreState } from "../../../../reducers/types";
import { IResult } from "redmond";

export const getLocation = (state: IStoreState): IResult | null =>
  state.experiencesSearch.location;

export const getLocationCategories = (state: IStoreState) =>
  state.experiencesSearch.locationCategories;

export const getLocationCategoriesLoading = (state: IStoreState) =>
  state.experiencesSearch.locationCategoriesLoading;

export const getHasLocationAutocompleteError = (state: IStoreState) =>
  state.experiencesSearch.hasLocationAutocompleteError;

export const getFromDate = (state: IStoreState) =>
  state.experiencesSearch.fromDate;

export const getUntilDate = (state: IStoreState) =>
  state.experiencesSearch.untilDate;
