import { CurrencyFormatters } from "halifax";
import React from "react";
import { WallStreetAmount } from "redmond";

export const CREDIT_BALANCE_DESCRIPTION = (name: string) =>
  `${name}'s travel credits`;

export const CREDIT_BALANCE_TEXT = (amount?: WallStreetAmount) => (
  <>
    <strong>
      {CurrencyFormatters.add(amount?.currency, undefined, "en-US").format(
        (amount?.amount && amount?.amount * -1) || 0
      )}
    </strong>{" "}
    available to spend on travel
  </>
);
export const CREDIT_BALANCE_APPLICABILITY_TEXT = "Valid on all bookings";
