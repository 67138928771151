import { Box, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import "./styles.scss";
import { KeywordSearchConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { Icon, IconName } from "halifax";
import { IResult } from "redmond/build/common";
import { IIdExperiences } from "redmond/build/experiences-module";
import { KeywordAutocomplete } from "../KeywordAutocomplete";

export interface IKeywordSearchProps extends KeywordSearchConnectorProps {
  displaySearchOnChange?: boolean;
}

export const KeywordSearch = (props: IKeywordSearchProps) => {
  const { location } = props;
  const searchTitle = textConstants.SEARCH_TITLE(location?.label);

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdExperiences) : null;
    const opt = option ? (option.id as IIdExperiences) : null;
    return (
      !!selection &&
      !!opt &&
      selection.experiencesSelection.id === opt.experiencesSelection.id
    );
  };

  return (
    <Box className="experiences-availability-keyword-search-container">
      <Box className={clsx("experiences-availability-keyword-search-control")}>
        <Box className="search-components-section">
          <Typography className="search-title">{searchTitle}</Typography>
          <KeywordAutocomplete
            className={clsx("keyword-auto-complete", "b2b")}
            label={textConstants.KEYWORD_SEARCH_LABEL}
            getOptionSelected={getSelectedOption}
            customIcon={
              <Icon
                name={IconName.MagnifyingGlass}
                ariaLabel=""
                aria-hidden={true}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
