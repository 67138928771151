import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";

const mapStateToProps = () => {
  return {
    disabled: false,
  };
};

const mapDispatchToProps = {};

export const ConnectedExperiencesSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
