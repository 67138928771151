import { connect, ConnectedProps } from "react-redux";

import { ExperiencesSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getLocation,
  getLocationCategoriesLoading,
  getLocationCategories,
  getHasLocationAutocompleteError,
  getFromDate,
  getUntilDate,
} from "../../reducer/index";

const mapStateToProps = (state: IStoreState) => {
  return {
    location: getLocation(state),
    locationCategories: getLocationCategories(state),
    locationCategoriesLoading: getLocationCategoriesLoading(state),
    hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
  };
};

const mapDispatchToProps = {
  resetFilters: () => {
    null;
  },
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesSearchControl = connector(
  ExperiencesSearchControl
);
