import { ConnectedProps, connect } from "react-redux";
import { withRouter } from "react-router";
import { ExperiencesAvailability } from "./component";
import { IStoreState } from "../../reducers/types";
import { getFromDate, getLocation, getUntilDate } from "../search/reducer";
import {
  getExperiencesAvailabilityCallState,
  getExperiencesByCategory,
  getExperiencesMap,
} from "./reducer";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccountWithLargestValue,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../rewards/reducer";
import { fetchInitialExperiencesAvailability } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getLocation(state),
  experiencesAvailabilityCallState: getExperiencesAvailabilityCallState(state),
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
  experiencesMap: getExperiencesMap(state),
  experiencesByCategory: getExperiencesByCategory(state),
  rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
});

const mapDispatchToProps = {
  fetchInitialExperiencesAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ExperiencesAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedExperiencesAvailability = withRouter(
  connector(ExperiencesAvailability)
);
