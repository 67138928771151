import axios from "axios";
import { config } from "../../config";
import { ExperiencesAvailabilityRequest } from "redmond";
import { experiencesAvailabilityApiPrefix } from "../paths";

export const fetchExperiencesAvailability = (
  body: ExperiencesAvailabilityRequest
): Promise<ExperiencesAvailabilityRequest> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(experiencesAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
