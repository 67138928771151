export const FETCH_INITIAL_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/FETCH_INITIAL_EXPERIENCES_AVAILABILITY";
export type FETCH_INITIAL_EXPERIENCES_AVAILABILITY =
  typeof FETCH_INITIAL_EXPERIENCES_AVAILABILITY;

export const FETCH_MORE_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/FETCH_MORE_EXPERIENCES_AVAILABILITY";
export type FETCH_MORE_EXPERIENCES_AVAILABILITY =
  typeof FETCH_MORE_EXPERIENCES_AVAILABILITY;

export const STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY =
  "experiencesAvailability/STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY";
export type STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY =
  typeof STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY;

export const SET_EXPERIENCES_AVAILABILITY_RESULTS =
  "experiencesAvailability/SET_EXPERIENCES_AVAILABILITY_RESULTS";
export type SET_EXPERIENCES_AVAILABILITY_RESULTS =
  typeof SET_EXPERIENCES_AVAILABILITY_RESULTS;

export const SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED =
  "experiencesAvailability/SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED";
export type SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED;

export const FETCH_KEYWORD_CATEGORIES =
  "experiencesAvailability/FETCH_KEYWORD_CATEGORIES";
export type FETCH_KEYWORD_CATEGORIES = typeof FETCH_KEYWORD_CATEGORIES;

export const SET_KEYWORD_CATEGORIES =
  "experiencesAvailability/SET_KEYWORD_CATEGORIES";
export type SET_KEYWORD_CATEGORIES = typeof SET_KEYWORD_CATEGORIES;

export const SET_KEYWORD = "experiencesAvailability/SET_KEYWORD";
export type SET_KEYWORD = typeof SET_KEYWORD;

export const SET_KEYWORD_AUTOCOMPLETE_ERROR =
  "experiencesAvailability/SET_KEYWORD_AUTOCOMPLETE_ERROR";
export type SET_KEYWORD_AUTOCOMPLETE_ERROR =
  typeof SET_KEYWORD_AUTOCOMPLETE_ERROR;
